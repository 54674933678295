import React, { useEffect, useState } from "react";
import Styles from "./ClaimModal.module.sass";
import { Row, Col, Tooltip } from "antd";
import TextInput from "../../../components/NewTextInput";
import Shade from "../../../images/stakeshade.png";
import { GetUserAssets, WithrawERC_20 } from "../../../services/ApiServices";
import Leftborder from "../../../images/leftborder.png";
import Loader from "../../../components/Loader";
import Bonze from "../../../images/bonez.png";
import Kibblez from "../../../images/kibblez.png";
import Button from "../../../components/Button";
import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
import PrimaryButton from "../../../components/PrimaryButton";

const ClaimModal = ({
  bonez,
  getBonez,
  kibblez,
  getKibblez,
  claimModalClose,
  getWithdrawHistory,
}) => {
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();
  const withdraw = async () => {
    if (bonez.totalAmount > 10) {
      let body = {
        address: account,
      };
      setIsLoading(true);
      let result;
      try {
        result = await WithrawERC_20(body);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.message, { variant: "error" });
        setIsLoading(false);
      }
      try {
        if (result.success) {
          setIsLoading(false);
          enqueueSnackbar("Your Withdraw request for $BONEZ ERC-20 Submitted", {
            variant: "info",
          });
          getBonez(account);
          claimModalClose();
          getKibblez(account);
          getWithdrawHistory(account);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
        claimModalClose();
      }
    } else {
      enqueueSnackbar("Minimum 10 BONEZ required for withdrawal.", {
        variant: "error",
      });
    }
  };

  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        {/* <span className={Styles.yield}>WITHDRAW</span>  */}
        WITHDRAW BONEZ ERC-20
        <Tooltip color="#2F2F2F" title="$BONEZ ERC-20 token in Polygon network">
          <i className="fa fa-fr fa-info-circle" style={{ fontSize: "16px" }} />
        </Tooltip>
      </div>
      <div className={Styles.body}>
        <Row>
          <Col span={17}>
            <div className={Styles.field}>$BONEZ Balance</div>
          </Col>
          <Col span={2}>
            <div className={Styles.field}>:</div>
          </Col>
          <Col span={5}>
            <div className={Styles.value}>
              {Intl.NumberFormat("en-US").format(
                kibblez.bonezBalance ? kibblez.bonezBalance : 0
              )}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={17}>
            <div className={Styles.field}>
              $BONEZ Withdrawable
              <Tooltip
                color="#2F2F2F"
                title="Withdrawable $BONEZ after two weeks of cooling period"
              >
                {" "}
                <i className="fa fa-fr fa-info-circle" />
              </Tooltip>
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.field}>:</div>
          </Col>
          <Col span={5}>
            <div className={Styles.value}>
              {Intl.NumberFormat("en-US").format(
                bonez.totalAmount ? bonez.totalAmount : 0
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={17}>
            <div className={Styles.field}>
              Withdrawal Fee ({bonez.percentage ? bonez.percentage : 30}%)
            </div>
          </Col>
          <Col span={2}>
            <div className={Styles.field}>:</div>
          </Col>
          <Col span={5}>
            <div className={Styles.value}>
              {Intl.NumberFormat("en-US").format(
                bonez.withdrawFee ? bonez.withdrawFee : 0
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={17}>
            <div className={Styles.field}>You will receive $BONEZ ERC-20</div>
          </Col>
          <Col span={2}>
            <div className={Styles.field}>:</div>
          </Col>
          <Col span={5}>
            <div className={Styles.value}>
              {Intl.NumberFormat("en-US").format(
                bonez.claimedAmount ? bonez.claimedAmount : 0
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <PrimaryButton
          label="Withdraw"
          loading={isLoading}
          loadingtext="Processing"
          onClick={withdraw}
          className={Styles.button}
        />
      </div>
    </div>
  );
};
export default ClaimModal;
