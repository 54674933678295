import React, { useState, useEffect, useCallback } from "react";
import { Wheel } from "paramall-wheel-of-fortune";
import styles from "./CustomizeSpinWheel.module.sass";
import SpinArrow from "../../images/spinArrow.png";
import { useSnackbar } from "notistack";
import { Col, Divider, Row, Statistic, Tooltip } from "antd";
import { Input } from "antd";
import TextInput from "../../components/TextInput";
import TransparentButton from "../../components/TransparentButton";
import { IoMdClose } from "react-icons/io";
import WidgetCode from "./WidgetCode";
import { useSelector } from "react-redux";
import Web3 from "web3";

const CustomizeSpinWheel = () => {
  const state = useSelector((state) => state);
  const [user, setUser] = useState({});
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState([]);
  const [data, setData] = useState([]);
  const [fontFamily, setFontFamily] = useState("Poppins");
  const [fontSize, setFontSize] = useState(16);
  const [radiusLineWidth, setRadiusLineWidth] = useState(3);
  const [radiusLineColor, setRadiusLineColor] = useState("#ffb602");
  const [backgroundLineWidth, setbackgroundLineWidth] = useState(10);
  const [backgroundColor, setBackgroundColor] = useState("#fa127c");
  const [address, setAddress] = useState("");
  const [editIndex, setEditIndex] = useState();
  const [error, setError] = useState("");
  const handleAdd = async () => {
    let query = {
      option: option,
      style: { backgroundColor: "#EB5757", textColor: "#000" },
    };
    setData([query].concat(data));
    setOption("");
  };
  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);
  useEffect(() => {
    if (options.length) setData(data);
    else
      setData([
        {
          option: "1",
          style: { backgroundColor: "#EB5757", textColor: "#000000" },
        },
        {
          option: "Loss",
          style: { backgroundColor: "#121212", textColor: "#ffffff" },
        },
        {
          option: "10",
          style: { backgroundColor: "#00A2FD", textColor: "#000000" },
        },
        {
          option: "100",
          style: { backgroundColor: "#BB6BD9", textColor: "#000000" },
        },
        {
          option: "Free Spin",
          style: {
            backgroundColor: "#FA26A0",
            textColor: "#000000",
          },
        },
        {
          option: "Loss",
          style: { backgroundColor: "#000000", textColor: "#ffffff" },
        },
        {
          option: "1,000",
          style: { backgroundColor: "#CDB4DB", textColor: "#000000" },
        },

        {
          option: "10,000 ",
          style: { backgroundColor: "#A9333A", textColor: "#000000" },
        },
        {
          option: "5",
          style: { backgroundColor: "#D4F6CC", textColor: "#000000" },
        },
        {
          option: "100",
          style: { backgroundColor: "#61876E", textColor: "#000000" },
        },
      ]);
  }, [options]);
  const handleOnchange = (e) => {
    setOption(e.target.value);
  };
  const handleRemove = (index) => {
    setData([...data.slice(0, index), ...data.slice(index + 1)]);
    setOptions([...options.slice(0, index), ...data.slice(index + 1)]);
  };
  console.log(data,options);
  const update = (index, property, value) => {
    if (property == "option") {
      var updatedItems = [...data];
      // var itemToUpdate = updatedItems[index];
      // var updatedDetails[index] = { ...itemToUpdate, [property]: value };
      // var updatedItem = { ...itemToUpdate, updatedDetails };
      updatedItems[index] = { ...updatedItems[index], [property]: value };;
    } else {
      var updatedItems = [...data];
      var itemToUpdate = updatedItems[index];
      var updatedDetails = { ...itemToUpdate.style, [property]: value };
      var updatedItem = { ...itemToUpdate, style: updatedDetails };
      updatedItems[index] = updatedItem;
    }
    setData(updatedItems);
    setOptions(updatedItems);
  };
  const handleFontFamilyOnchange = (e) => {
    setFontFamily(e.target.value);
  };
  const handleFontSizeOnchange = (e) => {
    setFontSize(e.target.value);
  };
  const handleLineWidthOnchange = (e) => {
    setRadiusLineWidth(e.target.value);
  };
  const handleLineColorOnchange = (e) => {
    setRadiusLineColor(e.target.value);
  };
  const handleBorderWidthOnchange = (e) => {
    setbackgroundLineWidth(e.target.value);
  };
  const handleBorderColorOnchange = (e) => {
    setBackgroundColor(e.target.value);
  };
  const addressHandler = (e) => {
    let result = Web3.utils.isAddress(e.target.value);
    if (result) {
      setError("");
      setAddress(e.target.value);
    } else {
      setAddress("");
      setError("Enter valid Ethereum Address");
    }
  };
  return (
    <div className={styles.spin}>
      <div className={styles.heading}>Customize Your Spin Wheel</div>
      <Row className={styles.preview}>
        <Col xs={24} sm={24} lg={12} md={12} className={styles.content}>
          <div className={styles.options}>
            <div className={styles.optionInput}>
              <TextInput
                label="Enter the Spin Options"
                onChange={handleOnchange}
                value={option}
              />
            </div>
            <div className={styles.addButton}>
              <TransparentButton label="Add" onClick={handleAdd} />
            </div>
          </div>

          {data.length ? (
            <div className={styles.data}>
              <Row className={styles.row}>
                {data.map((data, index) => (
                  <Col
                    md={11}
                    lg={11}
                    sm={23}
                    xs={23}
                    offset={1}
                    key={index}
                    className={styles.option}
                  >
                    <Row>
                      <Col span={22}>
                        {editIndex != index ? (
                          <div
                            className={styles.reward}
                            onClick={() => {
                              setEditIndex(index);
                            }}
                          >
                            {data.option}
                          </div>
                        ) : (
                          <div>
                            <input
                              type="text"
                              className={styles.activeInput}
                              defaultValue={data.option}
                              onChange={(e) =>
                                update(index, "option", e.target.value)
                              }
                            />
                          </div>
                        )}
                      </Col>
                      <Col span={2}>
                        <IoMdClose
                          onClick={() => {
                            handleRemove(index);
                          }}
                          className={styles.icon}
                        />
                      </Col>
                    </Row>
                    <Row className={styles.row}>
                      <Col span={11} offset={1}>
                        <div className={styles.menu}>Background Color</div>
                        <Input
                          type="color"
                          value={data.style.backgroundColor}
                          onChange={(e) =>
                            update(index, "backgroundColor", e.target.value)
                          }
                        />
                      </Col>
                      <Col span={11} offset={1}>
                        <div className={styles.menu}>Text Color</div>

                        <Input
                          type="color"
                          value={data.style.textColor}
                          onChange={(e) =>
                            update(index, "textColor", e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}
          <Row className={styles.menuRow}>
            <Col className={styles.menuCol} xs={24} sm={24} lg={11} md={11}>
              <TextInput
                label="Font Family"
                onChange={handleFontFamilyOnchange}
                value={fontFamily}
              />
            </Col>
            <Col className={styles.menuCol} xs={24} sm={24} lg={11} md={11}>
              <TextInput
                label="Font Size"
                type="number"
                onChange={handleFontSizeOnchange}
                value={fontSize}
              />
            </Col>
          </Row>
          <Row className={styles.menuRow}>
            <Col className={styles.menuCol} xs={24} sm={24} lg={11} md={11}>
              <TextInput
                label="Radius Line width"
                type="number"
                onChange={handleLineWidthOnchange}
                value={radiusLineWidth}
              />
            </Col>
            <Col className={styles.menuCol} xs={24} sm={24} lg={11} md={11}>
              <div className={styles.radius}>Radius Line Color</div>
              <Input
                type="color"
                value={radiusLineColor}
                onChange={handleLineColorOnchange}
              />
            </Col>
          </Row>
          <Row className={styles.menuRow}>
            <Col className={styles.menuCol} xs={24} sm={24} lg={11} md={11}>
              <TextInput
                label="Outer Border width"
                type="number"
                onChange={handleBorderWidthOnchange}
                value={backgroundLineWidth}
              />
            </Col>
            <Col className={styles.menuCol} xs={24} sm={24} lg={11} md={11}>
              <div className={styles.radius}>Outer Border Color</div>

              <Input
                type="color"
                value={backgroundColor}
                onChange={handleBorderColorOnchange}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <TextInput
                label="User Address"
                onChange={addressHandler}
                placeholder="Ex: 0x0000000000000000000000000000000000000000"
              />
              {error && <div className={styles.error}>{error}</div>}
            </Col>
          </Row> */}
        </Col>
        <Col xs={24} sm={24} lg={12} md={12} className={styles.previewSpin}>
          <div className={styles.wheel}>
            {data.length && (
              <Wheel
                mustStartSpinning={false}
                prizeNumber={0}
                data={data}
                backgroundColors={["white", "yellow"]}
                textColors={["#ffffff"]}
                fontFamily={fontFamily}
                fontSize={fontSize}
                radiusLineColor={radiusLineColor}
                radiusLineWidth={radiusLineWidth}
                innerBorderColor={radiusLineColor}
                outerBorderWidth={backgroundLineWidth}
                outerBorderColor={backgroundColor}
                onStopSpinning={() => {}}
                pointerProps={{
                  src: SpinArrow,
                  style: {
                    transform: "rotate(0.75turn)",
                    width: deviceWidth > 600 ? "50px" : "35px",
                    right: 0,
                    left: 0,
                    margin: "0 auto",
                    top: "-15px",
                  },
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <WidgetCode
        user={user}
        data={data}
        fontFamily={fontFamily}
        fontSize={fontSize}
        radiusLineWidth={radiusLineWidth}
        radiusLineColor={radiusLineColor}
        backgroundLineWidth={backgroundLineWidth}
        backgroundColor={backgroundColor}
      />
    </div>
  );
};
export default CustomizeSpinWheel;
