import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Image from "../Image";
import Notification from "./Notification";
import User from "./User";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import {
  getUser,
  getLandContract,
  getCapsuleContract,
  getUSD,
  getAssetContract,
} from "../../redux";
import Lock from "../../images/lock.png";
import { isMobile } from "react-device-detect";
import Twitter from "../../images/twitter.png";
import Discord from "../../images/discordIcon.png";
import Opensea from "../../images/opensea.png";
import { AiOutlineAlignLeft } from "react-icons/ai";
import Menu from "../../images/menubar.png";
import Modal from "../NewModal";
import Stake from "./Stake";
import Bank from "../../screens/Bank";
import { Alert, Drawer } from "antd";
import Account from "./Account";
import DrawerContent from "./Drawer";
import { CloseOutlined } from "@ant-design/icons";
import { MdKeyboardArrowUp, MdOutlineClose } from "react-icons/md";
import ProfileImg from "../../images/NewProfile.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import ProfileDrawerContent from "./ProfileDrawer";
import Logo from "../../images/belugaOs/logo.png";
import { CgMenuRight } from "react-icons/cg";
const nav = [
  {
    url: "https://www.belugaos.com",
    title: "Home",
    islogin: false,
  },
  {
    url: "https://www.belugaos.com/our-history",
    title: "About",
    isLogin: false,
  },
  {
    url: "https://www.belugaos.com/whitepaper",
    title: "Whitepaper",
    isLogin: false,
  },
  {
    url: "https://doglit.com/",
    title: "Voice Payments",
    isLogin: false,
  },
  {
    url: "https://www.belugaos.com/faq",
    title: "FAQ",
    isLogin: false,
  },
];

const Headers = ({ history, account, active, connect, logout }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [callApi, setCallApi] = useState(false);
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState({ id: "" });
  const [sticking, setSticking] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [searchvalue, setSearchValue] = useState("");
  const [stick, setStick] = useState(false);
  const [pathNum, setPathNum] = useState(0);
  const [isStakeModal, setIsStakeModal] = useState(false);
  const [isBankModal, setIsBankModal] = useState(false);
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const [submenu, setSubmenu] = useState();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (history && !isBankModal && !isStakeModal && !visible) {
      let urlName = history.location.pathname.split("/");

      switch (urlName[1]) {
        case "":
          setPathNum(0);
          break;
        case "spaceship":
        case "spaceshooter":
        case "race":
        case "live-race":
        case "spaceshipGame":
          setPathNum(3);
          break;
        case "marketplace":
          setPathNum(2);
          break;
        case "lottery":
          setPathNum(1);
          break;
        case "breeding":
        case "breeding_g1":
          setPathNum(4);
          break;
        default:
          setPathNum(1000);
          break;
      }
    }
  });

  useEffect(() => {
    setUser(state.User.user);
  }, [state.User.user]);
  const userDetails = async (uid) => {
    dispatch(getUser(uid));
  };

  const onCloseNotification = () => {
    setShowWarningBanner(false);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onClose1 = () => {
    setShow(false);
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisibleNav(false);
        setVisible(false);
      }}
    >
      <header className={stick ? styles.sticky : styles.header}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={Logo} />
          </div>
          <div className={styles.heading}>
            <Link to={"/"}>
              <h3>BelugaOS</h3>
            </Link>
          </div>
          <div className={styles.container1}>
            <div
              className={cn(styles.wrapper, { [styles.active]: visibleNav })}
            >
              {visibleNav && (
                <div
                  className={cn(styles.sideNav, {
                    [styles.active]: visibleNav,
                  })}
                >
                  <div className={styles.sideNavHead}></div>
                </div>
              )}

              <nav className={styles.nav}>
                {nav.map((x, index) => (
                  <div key={index}>
                    <a className={styles.link} href={x.url} target="_blank">
                      {x.title}
                    </a>
                  </div>
                ))}
              </nav>
            </div>
            {/* {active && (
              <Account
                className="user"
                userDetail={user}
                stick={stick}
                account={account}
                active={active}
                connect={connect}
                logout={logout}
                showDrawer={() => setShow(true)}
              />
            )} */}

            <User
              className="user"
              userDetail={user}
              stick={stick}
              account={account}
              active={active}
              connect={connect}
              logout={logout}
            />
            <div className={styles.menu} onClick={() => setOpen(true)}>
              {/* <AiOutlineAlignLeft className={styles.icon} /> */}
              <CgMenuRight className={styles.icon} />
            </div>
            {/* <button
              className={cn(styles.burger, { [styles.active]: visibleNav })}
              onClick={() => setVisibleNav(!visibleNav)}
            >
              <img src={Menu} />
            </button> */}
          </div>
        </div>
        <Drawer
          title={<div className={styles.drawerHeader1}><img src={Logo} alt='logo'/> <h3>BelugaOS</h3></div>}
          placement="left"
          size={"default"}
          onClose={onClose}
          open={open}
          closable={false}
          contentWrapperStyle={{
            backgroundColor: "rgb(0, 0, 0)",
            width: "80%",
          }}
          // closeIcon={<MdOutlineClose className={styles.closeIcon} />}
          drawerStyle={{ backgroundColor: "rgb(0, 0, 0)" }}
          headerStyle={{
            backgroundColor: "rgb(0, 0, 0)",
            borderBottom: "none",
          }}
          extra={
            <MdOutlineClose
              className={styles.closeIcon}
              onClick={() => onClose()}
            />
          }
          width={300}
          maskStyle={{ backdropFilter: "blur(7px)", opacity: 0.8 }}
        >
          <DrawerContent
            history={history}
            onClose={onClose}
            active={active}
            connect={connect}
            logout={logout}
          />
        </Drawer>
        <Drawer
          title={<div className={styles.drawerHeader}>Wallet</div>}
          placement="right"
          size={"default"}
          onClose={onClose1}
          open={show}
          closable={false}
          contentWrapperStyle={{ backgroundColor: "#232323" }}
          extra={
            <MdOutlineClose
              className={styles.closeIcon}
              onClick={() => onClose1()}
            />
          }
          drawerStyle={{ backgroundColor: "#232323" }}
          headerStyle={{
            backgroundColor: "#232323",
            justifyContent: "space-between",
            borderBottom: "1px solid #9698A1",
          }}
          width={300}
          maskStyle={{ backdropFilter: "blur(7px)", opacity: 0.8 }}
          bodyStyle={{ padding: "0px" }}
        >
          <ProfileDrawerContent
            history={history}
            onClose={onClose1}
            account={account}
            logout={logout}
          />
        </Drawer>
      </header>
      {showWarningBanner && !account && (
        <Alert
          message="Connect your Metamask wallet and try again"
          type="warning"
          closable
          onClose={onCloseNotification}
          style={{
            top: "72px",
            backgroundColor: "#ee9200",
            border: "none",
            fontWeight: 700,
            textAlign: "center",
          }}
        />
      )}
      <Modal
        visible={isStakeModal}
        onClose={() => setIsStakeModal(false)}
        close={styles.close}
        containerClassName={styles.modalContainer}
      >
        <Stake />
      </Modal>
      <Modal
        visible={isBankModal}
        onClose={() => setIsBankModal(false)}
        outerClassName={styles.bankmodal}
      >
        <Bank />
      </Modal>
    </OutsideClickHandler>
  );
};

export default Headers;
