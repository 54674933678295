import React from "react";
import catAnimationData from '../../json/404.json';
import LottieLoader from 'react-lottie-loader';
import styles from './NotFound.module.sass'
function Screen404() {
  return (
    <div className={styles.notFound}>
      <LottieLoader animationData={catAnimationData} />
    </div>
  );
}

export default Screen404;