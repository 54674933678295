import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
// import Home from "./screens/Home";

import { SnackbarProvider } from "notistack";

import { ethers } from "ethers";
import { Web3ReactProvider } from "@web3-react/core";
import { Provider as Web3Provider } from "@ethersproject/providers";
import "antd/dist/antd.css";
import Screen404 from "./screens/Screen404";

import Spin from "./screens/Spin";
import CustomizeSpinWheel from "./screens/CustomizeSpinWheel";
function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}
function App(props) {
  const reload = () => window.location.reload();

  return (
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate={true}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Page>
                  <Spin props={props} />
                </Page>
              }
            />
            <Route
              exact
              path="/customizeSpinWheel"
              element={
                <Page>
                  <CustomizeSpinWheel props={props} />
                </Page>
              }
            />
            <Route
              exact
              path="/home/:id/:code"
              element={
                <Page>
                  <Spin props={props} />
                </Page>
              }
            />
            <Route
              exact
              path="/404"
              element={
                <Page>
                  <Screen404 />
                </Page>
              }
            />
            <Route
              path="*"
              exact={true}
              element={
                <Page>
                  <Screen404 />
                </Page>
              }
            />
          </Routes>
        </Router>
      </Web3ReactProvider>
    </SnackbarProvider>
  );
}

export default App;
