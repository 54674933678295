import React, { useState } from "react";
import styles from "./SpinModal.module.sass";
import LottieLoader from "react-lottie-loader";
import LoseJson from "../../../json/lose.json";
import WonJson from "../../../json/won.json";
const SpinModal = ({ reward }) => {
  return (
    <div className={styles.spin}>
      {reward == "Loss" ? (
        <div className={styles.lose}>
          <LottieLoader animationData={LoseJson} className={styles.loseLottie} />
          <div className={styles.message}>
            <span>Oops!</span> You Loss
          </div>
        </div>
      ) : (
        <div className={styles.win}>
          <LottieLoader animationData={WonJson} className={styles.lottie} />

          <div className={styles.message}>
            <span>Hurray!</span> You won {reward}
          </div>
          {/* <div className={styles.notes}>
            <span>Important notes: </span>
              <li>The reward will transfer within 24hrs.</li>
          </div> */}
        </div>
      )}
    </div>
  );
};
export default SpinModal;
