import React from "react";
import styles from "./Footer.module.sass";
import { Col, Row } from "antd";
import Logo1 from "../../images/belugaOs/logo.png";
import PrimaryButton from "../PrimaryButton";
import TransparentButton from "../TransparentButton";
const Footers = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.divider}></div>
      <Row>
        <Col xs={24} md={24} sm={24} lg={8}>
          <div className={styles.logoContainer}>
            <img src={Logo1} alt="" className={styles.logo1} />
            <div className={styles.heading}>
              <a href="/">
                <h3>BelugaOS</h3>
              </a>
            </div>
          </div>
          <br />
          <div className={styles.subhead}>
            The future of payments is in-game.
          </div>
        </Col>
        <Col xs={12} md={12} sm={12} lg={4}>
          <div className={styles.subhead}>Community</div>
          <br />
          <a
            href="https://t.me/BelugaOS_channel"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Telegram
          </a>
          <a
            href="https://www.belugaos.com/whitepaper"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Whitepaper
          </a>
          <a
            href="https://doglit.com/"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            Voice Payments
          </a>
        </Col>
        <Col xs={12} md={12} sm={12} lg={4}>
          <div className={styles.subhead}>Important</div>
          <br />
          <a
            href="https://www.belugaos.com/our-history"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            About
          </a>
          <a
            href="https://www.belugaos.com/faq"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            FAQ
          </a>
        </Col>
        <Col xs={24} md={24} sm={24} lg={8}>
          <div className={styles.heading}>
            <h3>Receive notifications about BelugaOS</h3>
          </div>
          <div className={styles.sendEmail}>
            <input
              type="text"
              placeholder="Email"
              className={styles.emailInput}
            />
            <div className={styles.sendBtn}>
              <TransparentButton label="Send" />
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.flexCopyright}>
        <span>
          Copyright 2023 BelugaOS . All Rights Reserved.{" "}
          <a
            href="https://www.belugaos.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://www.belugaos.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </span>
      </div>
      <div className={styles.stickyFooter}>
        Disclaimer: $BBI is in beta and not a financial product. This is not an
        offer to sell. Use at your own risk. We make no guarantees and
        warranties express or implied. It is provided “As Is”. We are planning
        to add $BBI utility into all our products yet this is To Be Determined.
      </div>
    </footer>
  );
};

export default Footers;
