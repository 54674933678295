import React, { useState, useEffect, useRef } from "react";
import TransactionHistories from "../../../components/TransactionHistories";
import {
  GetBBIHistory,
  GetWithdrawHistory,
} from "../../../services/ApiServices";
import { useWeb3React } from "@web3-react/core";
import styles from '../Spin.module.sass'
const TransactionsLeaderBord = () => {
  const [bbiHistoires, setBBIHistories] = useState([]);
  const [isBBILoading, setIsBBILoading] = useState(false);
  const [ERC_20Histoires, setERC_20Histories] = useState([]);
  const [isERC_20Loading, setIsERC_20Loading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();

  useEffect(() => {
    if (account) {
      getBBIHistory(account);
      getWithdrawHistory(account);
    }
  }, [account]);
  const getBBIHistory = async (id) => {
    setIsBBILoading(true);
    let result;
    try {
      result = await GetBBIHistory(id);
    } catch (e) {
      console.log(e);
      setIsBBILoading(false);
    }
    try {
      if (result.success) {
        setIsBBILoading(false);
        setBBIHistories(result.result.docs);
      }
    } catch (e) {
      setIsBBILoading(false);
      console.log(e);
    }
  };
  const getWithdrawHistory = async (id) => {
    setIsERC_20Loading(true);
    let result;
    try {
      result = await GetWithdrawHistory(id);
    } catch (e) {
      console.log(e);
      setIsERC_20Loading(false);
    }
    try {
      if (result.success) {
        setIsERC_20Loading(false);
        setERC_20Histories(result.result.docs);
      }
    } catch (e) {
      setIsERC_20Loading(false);
      console.log(e);
    }
  };
  return (
    <div className={styles.leaderBoard}>
      <TransactionHistories
        isBBILoading={isBBILoading}
        bbiHistoires={bbiHistoires}
        ERC_20Histoires={ERC_20Histoires}
        isERC_20Loading={isERC_20Loading}
      />
    </div>
  );
};
export default TransactionsLeaderBord;
