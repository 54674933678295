import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./fonts/Polaris/Polaris.otf";
import "./fonts/Heebo/static/Heebo-SemiBold.ttf";
import "./fonts/Heebo/static/Heebo-Thin.ttf";
import "./fonts/Heebo/static/Heebo-Regular.ttf";
import "./fonts/deltha/Deltha.otf";
import "./fonts/RocketRinder/RocketRinder.otf";
import "./fonts/anita-semi-square/Anitasemisquare.ttf";
import "./fonts/Chakra/ChakraPetch-Bold.woff";
import "./fonts/Chakra/ChakraPetch-Medium.woff";
import "./fonts/Chakra/ChakraPetch-Regular.woff";
import "./fonts/Chakra/ChakraPetch-SemiBold.woff";
import "./fonts/Chakra/ChakraPetch-Light.woff";
import "./fonts/SpaceGrotesk/SpaceGrotesk.ttf";
import "./fonts/walsheim/GTWalsheimPro-Light.ttf";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.unregister();
