import { Col, Row, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { minifyAddress } from "../../../utils/helpers";
import styles from "./SpinLeaderBoard.module.sass";
import { useLocation, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import Bonez from "../../../images/bonez.png";
import TableLoader from "../../../components/TableLoader";
import Pagenation from "../../../components/Pagenation";
import Dropdown from "../../../components/Dropdown";
const SpinLeaderBoard = ({
  leaderboard,
  loading,
  totalDocs,
  setPage,
  page,
  leaderboardType,
}) => {
  let options = ["My Rewards", "All Rewards"];
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [type, setType] = useState(options[1]);
  useEffect(() => {
    if (type) leaderboardType(1,type);
  }, [type]);
  return (
    <div className={styles.table}>
      <div className={styles.spin}>
        <div className={styles.top}>
          <h3 className={styles.heading}>
            {type == "My Rewards" ? "My Recent Spins" : "All Reward"}
          </h3>
          <div className={styles.dropdown}>
            <Dropdown options={options} setValue={setType} value={type} />
          </div>
        </div>
        <Row className={styles.header}>
          <Col span={type == "My Rewards" ? 6 : 5} className={styles.head}>
            Timestamp
          </Col>
          <Col span={type == "My Rewards" ? 6 : 5} className={styles.head}>
            Reward
          </Col>
          <Col span={type == "My Rewards" ? 6 : 5} className={styles.head}>
            Spin type
          </Col>

          {type == "All Rewards" && (
            <Col span={type == "My Rewards" ? 6 : 5} className={styles.head}>
              Wallet address
            </Col>
          )}
          <Col span={type == "My Rewards" ? 6 : 4} className={styles.head}>
            Status
          </Col>
        </Row>
        {loading ? (
          <TableLoader length={10} />
        ) : !leaderboard.length ? (
          <div className={styles.nodata}>No data available</div>
        ) : (
          leaderboard?.map((data, index) => (
            <Row
              key={index}
              className={index % 2 == 0 ? styles.highlightedRow : styles.row}
            >
              <Col span={type == "My Rewards" ? 6 : 5} className={styles.date}>
                {/* {moment(data.date).format("DD/MM/YYYY")} */}{" "}
                {moment(data.timestamp).fromNow()}
              </Col>

              <Col
                span={type == "My Rewards" ? 6 : 5}
                className={styles.reward}
              >
                <span>{data.reward == "Loss" ? "-" : data.reward}</span>
              </Col>
              <Col
                span={type == "My Rewards" ? 6 : 5}
                className={styles.reward}
              >
                {data.extraSpin ? (
                  <div className={styles.extraBadge}>Premium Spin</div>
                ) : (
                  <div className={styles.freeBadge}>Free Spin</div>
                )}
              </Col>

              {type == "All Rewards" && (
                <Col
                  span={type == "My Rewards" ? 6 : 5}
                  className={styles.address}
                >
                  {minifyAddress(data.wallet, 5)}
                </Col>
              )}

              <Col
                span={type == "My Rewards" ? 6 : 4}
                className={styles.status}
              >
                <span
                  className={
                    data.status == "SUBMITTED"
                      ? styles.submitted
                      : data.status == "PROCESSING"
                      ? styles.process
                      : styles.transfer
                  }
                >
                  {data.status}
                </span>
              </Col>
            </Row>
          ))
        )}
        <Pagenation
          onChange={(page) => {
            setPage(page);
          }}
          total={totalDocs}
          current={page}
        />
      </div>
    </div>
  );
};

export default SpinLeaderBoard;
