import React from "react";
import styles from "./customize.module.sass";
import PrimaryButton from "../PrimaryButton";
import { useNavigate } from "react-router";

const Customization = () => {
  let navigate = useNavigate()
  return (
    <div className={styles.referral}>
        <div className={styles.heading}>Customize Spin Wheel</div>
      <div className={styles.description}>Customize your Spin Wheel for your project with leaderboard.</div>
      <PrimaryButton className={styles.codeButton} onClick={() => navigate("/customizeSpinWheel") } label="Click Here" />
    </div>
  );
};

export default Customization;
