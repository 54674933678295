import React, { useState } from "react";
import styles from "./CouponModal.module.sass";
import TextInput from "../../../components/TransparentTextInput";
import PrimaryButton from "../../../components/PrimaryButton";
import { VerifyCouponCode } from "../../../services/ApiServices";
import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
const CouponModal = ({ onClose, increaseSpin }) => {
  const [coupon, setCoupon] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();

  const verifyCouponCode = async () => {
    setVerifyLoading(true);
    let body = { address: account, code: coupon, type: "Spin Wheel" };
    let result;
    try {
      result = await VerifyCouponCode(body);
    } catch (e) {
      console.log(e);
      setVerifyLoading(false);
      return enqueueSnackbar(e.message, { variant: "error" });
    }
    try {
      if (result.success) {
        onClose();
        increaseSpin();
        setVerifyLoading(false);
        enqueueSnackbar(result.message, { variant: "info" });
      }
    } catch (e) {
      console.log(e);
      setVerifyLoading(false);
      return enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  const CodeHandle = (e) => {
    setCoupon(e.target.value);
  };
  return (
    <div className={styles.spin}>
      <div className={styles.heading}>Coupon Code</div>
      <TextInput
        label="Enter Coupon Code"
        onChange={(e) => {
          CodeHandle(e);
        }}
      />
      <PrimaryButton
        onClick={verifyCouponCode}
        className={styles.continue}
        label="Continue"
        loading={verifyLoading}
      />
    </div>
  );
};
export default CouponModal;
