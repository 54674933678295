import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Notification.module.sass";
import Icon from "../../Icon";
import Loader from "../../Loader";
import { GetAsset } from "../../../services/ApiServices";

const Notification = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getAssets = async () => {
    setIsLoading(true);
    let result;

    try {
      result = await GetAsset();
    } catch (e) {
      console.log(e);
    }

    try {
      if (result.success) {
        setIsLoading(false);
        setAssets(result.posts);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getAssets();
  }, []);
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.notification, className)}>
        <button
          className={cn(styles.head, styles.active)}
          onClick={() => setVisible(!visible)}
        >
          <Icon name="notification" size="24" />
        </button>
        {visible && (
          <div className={styles.body}>
            <div className={cn("h4", styles.title)}>Notification</div>
            <div className={styles.list}>
              {assets.map((x, index) => (
                <Link
                  className={styles.item}
                  to={"/detail/" + x.id}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div className={styles.preview}>
                    {x.attachments[0].fileType == "image" ? (
                      <img
                        // srcSet={`${x.attachments[0].url} 2x`}
                        src={x.attachments[0].url}
                        alt="Card"
                      />
                    ) : (
                      <video
                        autoPlay
                        muted
                        loop
                        src={x.attachments[0].url}
                        type="video/mp4"
                      />
                    )}
                  </div>
                  <div className={styles.details}>
                    <div className={styles.subtitle}>{x.title}</div>
                    <div className={styles.price}>{x.earnedCoins}</div>
                    <div className={styles.date}>{x.userId.creation}</div>
                  </div>
                  <div
                    className={styles.status}
                    style={{ backgroundColor: "blue" }}
                  ></div>
                </Link>
              ))}
              {isLoading && <Loader className={styles.loader} />}
            </div>
            <Link
              className={cn("button-small", styles.button)}
              to="/activity"
              onClick={() => setVisible(!visible)}
            >
              See all
            </Link>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Notification;
