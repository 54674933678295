import React, { useEffect, useRef, useState } from "react";
import Styles from "./Bank.module.sass";
import { Row, Col, Tooltip } from "antd";
import TextInput from "../../components/TransparentTextInput";
import Shade from "../../images/stakeshade.png";
import HistoryShade from "../../images/historyshade.png";
import {
  ClaimKibblez,
  GetBonezHistory,
  GetClaimKibblez,
  GetERC_20History,
  GetKibblezHistory,
  GetUserAssets,
  GetWithdrawHistory,
} from "../../services/ApiServices";
import Leftborder from "../../images/leftborder.png";
import cn from "classnames";
import moment from "moment";
import Bonze from "../../images/bonez.png";
import Kibblez from "../../images/kibblez.png";
import TransactionHistories from "../../components/TransactionHistories";
import CountDown from "../../components/CountDown";
import Modal from "../../components/NewModal";
import ClaimModal from "./ClaimModal";
import { useSnackbar } from "notistack";
import { Spinner } from "react-bootstrap";
import Button from "../../components/Button";
import LeaderBoard from "../../components/LeaderBoard";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import BlackModal from "../../components/BlackModal";
const Bank = (props) => {
  const [kibblez, setKibblez] = useState({});
  const [bonez, setBonez] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isClaimModal, setIsClaimModal] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [kebblezHistoires, setKibblezHistories] = useState([]);
  const [isKibblezLoading, setIsKibblezLoading] = useState(false);
  const [bonezHistoires, setBonezHistories] = useState([]);
  const [isBonezLoading, setIsBonezLoading] = useState(false);
  const [ERC_20Histoires, setERC_20Histories] = useState([]);
  const [isERC_20Loading, setIsERC_20Loading] = useState(false);
  const [countdown, setCountdown] = useState(
    moment.utc("14:00", "HH:mm").valueOf()
  );
  const { account, active, activate, error, deactivate } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();

  const getKibblezHistory = async (id) => {
    setIsKibblezLoading(true);
    let result;
    try {
      result = await GetKibblezHistory(id);
    } catch (e) {
      console.log(e);
      setIsKibblezLoading(false);
    }
    try {
      if (result.success) {
        setIsKibblezLoading(false);
        setKibblezHistories(result.result);
      }
    } catch (e) {
      setIsKibblezLoading(false);
      console.log(e);
    }
  };
  const getBonezHistory = async (id) => {
    setIsBonezLoading(true);
    let result;
    try {
      result = await GetBonezHistory(id);
    } catch (e) {
      console.log(e);
      setIsBonezLoading(false);
    }
    try {
      if (result.success) {
        setIsBonezLoading(false);
        setBonezHistories(result.result.docs);
      }
    } catch (e) {
      setIsBonezLoading(false);
      console.log(e);
    }
  };
  const getWithdrawHistory = async (id) => {
    setIsERC_20Loading(true);
    let result;
    try {
      result = await GetWithdrawHistory(id);
    } catch (e) {
      console.log(e);
      setIsERC_20Loading(false);
    }
    try {
      if (result.success) {
        setIsERC_20Loading(false);
        setERC_20Histories(result.result.docs);
      }
    } catch (e) {
      setIsERC_20Loading(false);
      console.log(e);
    }
  };
  const getBonez = async (id) => {
    setIsERC_20Loading(true);
    let result;
    try {
      result = await GetERC_20History(id);
    } catch (e) {
      console.log(e);
      setIsERC_20Loading(false);
    }
    try {
      if (result.success) {
        setIsERC_20Loading(false);
        setBonez(result.claim);
      }
    } catch (e) {
      setIsERC_20Loading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    if (account) {
      getKibblez(account);
      getKibblezHistory(account);
      getBonezHistory(account);
      getBonez(account);
      getWithdrawHistory(account);
    }
  }, [account]);
  const getKibblez = async (id) => {
    setIsLoading(true);
    let result;
    try {
      result = await GetClaimKibblez(id);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    try {
      if (result.success) {
        setIsLoading(false);
        setKibblez(result.result);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const claimKibblez = async () => {
    if (kibblez.kibblezClaimable) {
      let body = {
        address: account,
        kibblez: kibblez.kibblezClaimable,
      };
      setIsClaimLoading(true);
      let result;
      try {
        result = await ClaimKibblez(body);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.message, { variant: "error" });
        setIsClaimLoading(false);
      }
      try {
        if (result.success) {
          setIsClaimLoading(false);
          enqueueSnackbar("Claim Submitted", { variant: "info" });
          getKibblez(account);
          getKibblezHistory(account);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    } else {
      enqueueSnackbar("You do not have $KIBBLEZ balance to claim", {
        variant: "error",
      });
    }
  };

  const claimModalClose = () => {
    setIsClaimModal(false);
  };

  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(() => {
      const currentDate = moment.utc().valueOf();
      if (countdown - currentDate < 0) {
        setCountdown(countdown + 86400000);
      }
    }, 1000);

    // move clean up function to here:
    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, [countdown]);

  return (
    <div className={cn("container")}>
      <p className={Styles.head}>
        BONEZ BANK
        {/* <span className={Styles.yield}> BONEZ</span> BANK */}
      </p>
      <div className={Styles.card}>
        {kibblez.isClaimable ? (
          <Row className={Styles.rowFlex}>
            <Col xs={24} md={8} sm={12}>
              <TextInput
                label="$KIBBLEZ Balance"
                value={Intl.NumberFormat("en-US").format(
                  kibblez.kibblezBalance ? kibblez.kibblezBalance : 0
                )}
                className={Styles.input}
                disabled={true}
                icon={Kibblez}
              />
            </Col>
            <Col xs={24} md={8} sm={12} className={Styles.margin}>
              <TextInput
                label="$KIBBLEZ Claimable"
                value={Intl.NumberFormat("en-US").format(
                  kibblez.kibblezClaimable ? kibblez.kibblezClaimable : 0
                )}
                className={Styles.input}
                disabled={true}
                icon={Kibblez}
              />
            </Col>
            <Col xs={24} md={8} sm={24}>
              <div className={Styles.button}>
                <PrimaryButton
                  loading={isClaimLoading}
                  loadingtext="Claiming"
                  label="Claim your KIBBLEZ"
                  onClick={() => {
                    claimKibblez();
                  }}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <Row className={Styles.rowFlex}>
            <Col xs={24} md={12} sm={12}>
              <TextInput
                label="$KIBBLEZ Balance"
                value={Intl.NumberFormat("en-US").format(
                  kibblez.kibblezBalance ? kibblez.kibblezBalance : 0
                )}
                className={Styles.input}
                disabled={true}
                icon={Kibblez}
              />
            </Col>
            <Col xs={24} md={12} sm={12}>
              <TextInput
                label="$KIBBLEZ Claimable"
                value={Intl.NumberFormat("en-US").format(
                  kibblez.kibblezClaimable ? kibblez.kibblezClaimable : 0
                )}
                className={Styles.input}
                disabled={true}
                icon={Kibblez}
              />
            </Col>
          </Row>
        )}
        <div className={Styles.lineBorder}></div>
        {/* <img src={Shade} className={Styles.shade} /> */}
        {kibblez.isClaimable || !kibblez.userFound ? (
          <div className={Styles.balance}>
            Since you don’t own a “SCOOPER 5000”,
            <br />
            Your KIBBLEZ balance will be reset at
            <span className={Styles.yield}> 2PM UTC</span>
            <br />
            <span className={Styles.coundown}>
              ( <CountDown asset={countdown} /> )
            </span>
          </div>
        ) : (
          <div className={Styles.balance}>
            You own a “SCOOPER 5000”,
            <br />
            Enjoy your balance without worrying about reset!
            <br />
            Your balance is automatically Claimed.
          </div>
        )}
        {/* <img src={Shade} className={Styles.shade} /> */}
        <div className={Styles.lineBorder}></div>
        <Row className={Styles.rowFlex}>
          <Col sm={24} md={12} xs={24}>
            <TextInput
              label="$BONEZ Balance"
              // description="Withdrawal fee 30%"
              value={Intl.NumberFormat("en-US").format(
                kibblez.bonezBalance ? kibblez.bonezBalance : 0
              )}
              className={Styles.input}
              icon={Bonze}
              disabled={true}
            />
            <div className={Styles.info}>
              Withdrawal fee {bonez.percentage ? bonez.percentage : 30}% &nbsp;
              <Tooltip
                color="#2F2F2F"
                title="Withdrawable $BONEZ after two weeks of cooling period"
              >
                {" "}
                <i className="fa fa-fr fa-info-circle" />
              </Tooltip>
            </div>
          </Col>
          <Col sm={24} md={12} xs={24}>
            <div className={Styles.button}>
              <PrimaryButton
                label=" Withdraw BONEZ ERC-20"
                onClick={() => {
                  setIsClaimModal(true);
                  // enqueueSnackbar("Coming soon", { variant: "info" });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <TransactionHistories
        kebblezHistoires={kebblezHistoires}
        isKibblezLoading={isKibblezLoading}
        isBonezLoading={isBonezLoading}
        bonezHistoires={bonezHistoires}
        ERC_20Histoires={ERC_20Histoires}
        isERC_20Loading={isERC_20Loading}
      />
      <LeaderBoard />
      <BlackModal
        visible={isClaimModal}
        outerClassName={Styles.modal}
        onClose={() => setIsClaimModal(false)}
        close={Styles.close}
        containerClassName={Styles.modalContainer}
      >
        <ClaimModal
          bonez={bonez}
          kibblez={kibblez}
          getBonez={getBonez}
          getKibblez={getKibblez}
          claimModalClose={claimModalClose}
          getWithdrawHistory={getWithdrawHistory}
        />
      </BlackModal>
    </div>
  );
};
export default Bank;
