import React, { useEffect, useState } from "react";
import Styles from "../TransactionHistories.module.sass";
import { Row, Col } from "antd";
import HistoryShade from "../../../images/historyshade.png";
import {
  GetKibblezHistory,
  GetUserAssets,
} from "../../../services/ApiServices";
import cn from "classnames";
import moment from "moment";
import Loader from "../../Loader";
import TableLoader from "../../TableLoader";

const history = [];

const BBITable = ({ isBBILoading, bbiHistoires }) => {
  return (
    <div className={Styles.history_table}>
      <div className={Styles.rowdata}>
        <Row>
          <Col span={8}>
            <div className={Styles.seller}>Date and Time</div>
          </Col>
          <Col span={8}>
            <div className={Styles.seller}>Earned BBI</div>
          </Col>

          <Col span={8}>
            <div className={Styles.seller}>Status</div>
          </Col>
        </Row>
      </div>
      {isBBILoading ? (
        <TableLoader />
      ) : bbiHistoires.length ? (
        <>
          {bbiHistoires.map((data, index) => {
            return (
              <Row className={Styles.rowdatabg} key={index}>
                <Col span={8}>
                  <div className={Styles.pricefield}>
                    {moment(data.timestamp * 1000)
                      .utc()
                      .format("DD-MM-YYYY hh:mm A")}{" "}
                    UTC
                  </div>
                </Col>

                <Col span={8}>
                  <div className={Styles.pricefield}>
                    {Intl.NumberFormat("en-US").format(data.bbi)}
                  </div>
                </Col>

                <Col span={8}>
                  <div className={Styles.status}>{data.status}</div>
                </Col>
              </Row>
            );
          })}
        </>
      ) : (
        <p className={Styles.empty}>No data available</p>
      )}
    </div>
  );
};
export default BBITable;
