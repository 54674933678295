import React, { useEffect, useState } from "react";
import Styles from "./WithdrawModal.module.sass";
import { Row, Col, Tooltip } from "antd";

import { GetUserAssets, WithrawERC_20 } from "../../services/ApiServices";
import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
import PrimaryButton from "../../components/PrimaryButton";
import { GoInfo } from "react-icons/go";
import { useDispatch } from "react-redux";

const WithdrawModal = ({ user, onClose, userDetails }) => {
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();
  const withdraw = async () => {
    if (user.bbi >= 1000) {
      let body = {
        address: account,
      };
      setIsLoading(true);
      let result;
      try {
        result = await WithrawERC_20(body);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.message, { variant: "error" });
        setIsLoading(false);
      }
      try {
        if (result.success) {
          setIsLoading(false);
          enqueueSnackbar("Your Withdraw request for $BBI ERC-20 Submitted", {
            variant: "info",
          });
          onClose();
          userDetails(account);
          // getBonez(account);
          // claimModalClose();
          // getKibblez(account);
          // getWithdrawHistory(account);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
        // claimModalClose();
      }
    } else {
      enqueueSnackbar("Minimum 1000 BBI required for withdrawal.", {
        variant: "error",
      });
    }
  };

  return (
    <div className={Styles.stake}>
      <div className={Styles.head}>
        {/* <span className={Styles.yield}>WITHDRAW</span>  */}
        WITHDRAW BBI ERC-20
        <Tooltip color="#f86c54" title="$BBI ERC-20 token in Etherium network">
          {/* <i className="fa fa-fr fa-info-circle" style={{ fontSize: "16px" }} /> */}
          <GoInfo className={Styles.info} />
        </Tooltip>
      </div>
      <div className={Styles.body}>
        <Row>
          <Col span={17}>
            <div className={Styles.field}>$BBI Balance</div>
          </Col>
          <Col span={2}>
            <div className={Styles.field}>:</div>
          </Col>
          <Col span={5}>
            <div className={Styles.value}>
              {Intl.NumberFormat("en-US").format(user.bbi ? user.bbi : 0)}
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <PrimaryButton
          label="Withdraw"
          loading={isLoading}
          loadingtext="Processing"
          onClick={withdraw}
          className={Styles.button}
        />
      </div>
      <div className={Styles.notes}>
        <div>Important Notes:</div>
        <li>Minimum withdrawal $BBI points are 1000 </li>
      </div>
    </div>
  );
};
export default WithdrawModal;
