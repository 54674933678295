import React from "react";
import Styles from "./labelButton.module.sass";
import { Spinner } from "react-bootstrap";
import ButtonJson from "../../json/button.json";
import LottieLoader from "react-lottie-loader";
const LabelButton = (props) => {
  return (
    <div className={props.outerContainer}>
      {props.loading ? (
        <button className={Styles.claimbutton} {...props}>
          <LottieLoader animationData={ButtonJson} />
          <span className={Styles.label}>
            {props.loadingtext ? props.loadingtext : props.label}
            <Spinner
              as="span"
              animation="border"
              style={{ marginLeft: "8px" }}
              size="sm"
              className={Styles.spinner}
              role="status"
              aria-hidden="true"
            />
          </span>
        </button>
      ) : props.disabled === true ? (
        <button className={Styles.disableButton} disabled={false}>
          <span className={Styles.disableLabel}>
            {" "}
            <LottieLoader
              animationData={ButtonJson}
              style={{ with: "100%", height: "100%" }}
            />
          </span>
        </button>
      ) : (
        <button className={Styles.claimbutton} {...props}>
          <div className={Styles.label}>
            <LottieLoader
              animationData={ButtonJson}
              style={{ with: "100%", height: "100%" }}
            />
          </div>
        </button>
      )}
    </div>
  );
};
export default LabelButton;
