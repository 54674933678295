const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

//----------------------------------- Spin Wheel -----------------------------------
export async function GetSpinDetails(id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/spin/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateFreeSpin(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/spin/freespin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateExtraSpin(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/spin/extraspin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSpinLeaderboard(query) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/spin/leaderboard" + query, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAllMyReferral(id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/refer/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateRefrerral(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/refer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBBIHistory(id, page) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/bbi/history/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetReferralBonez(id, page) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL + "/v1/spin/leaderboard/" + id + "?page=" + page,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetReferralTransactions(id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/referrer/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateExtraSpin(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/spin/extraspin", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//----------------------------------- Coupon Code -----------------------------------
export async function VerifyCouponCode(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/coupon/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// -------------------- Asset --------------------
export async function GetAsset(params, limit) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/assets?limit=150" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSearchAsset() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/assets?limit=150", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAssetById(id) {
  let response;
  try {
    // response = await fetch("/postDetails/posts?postId=" + id, {
    response = await fetch(BACKEND_URL + "/v1/asset/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetByTokenId(chainId, id, tokenId) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL + "/v1/asset/" + chainId + "/" + id + "/" + tokenId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetByToken(tokenId) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/asset/token/" + tokenId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUserAssets(uid, params) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/wallet/" + uid + "?" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetpaginate(page) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/assets?page=" + page, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBreeds() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/breeds", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetType() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/assetTypes", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSalesType() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/saleTypes", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetFaction() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/factions", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//------------------Get lambda Id for asset------
export async function GetPostId(id) {
  let response;
  try {
    response = await fetch(
      "https://olgbl0mgv4.execute-api.us-west-1.amazonaws.com/api/v1/asset?id=" +
        id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// --------------USD value for Eth------------

export async function GetUSD() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/usdprices", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response) {
    return response.json();
  }
}

// -------------- User --------------
export async function VerifyUser(uid) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/wallet/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBuyerUser(uid) {
  let response;
  try {
    response = await fetch(
      "https://zdt3pku4g4.execute-api.us-west-1.amazonaws.com/userDetails/v1/user?userId=" +
        uid,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function updateUser(body, uid) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/userDetails/users?user=" + uid, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetUsers() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/userDetails/users?userType=", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function verifyAddress(address) {
  let response;
  try {
    response = await fetch(
      "https://zdt3pku4g4.execute-api.us-west-1.amazonaws.com/userDetails/v1/user?userId=" +
        address,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function verifyBuyerAddress(address) {
  let response;
  try {
    response = await fetch(
      "https://zdt3pku4g4.execute-api.us-west-1.amazonaws.com/userDetails/v1/user?userId=" +
        address,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function createUser(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/userDetails/users", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function createBuyerUser(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/cyberuser/register", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    return await response.json();
  }
}
export async function userUpdate(body, id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/wallet/" + id, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// ---------------------- Rent ------------
export async function RentAssets(token) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/user/rents", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function RentHistories(id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/user/rents/history/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function RentAssetHistories(tokenId) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/token/rents/" + tokenId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//-------------------------History Details----------------------
export async function GetHistory(id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/asset/inventories/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.log(e);
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//-------------------------Bid Details----------------------

export async function GetBids(id) {
  let response;
  try {
    response = await fetch(
      "https://55oyg1h058.execute-api.us-west-1.amazonaws.com/bidsDetails/v1/bids?assetId=" +
        id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    console.log(e);
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//------------Add user into nft drop-----------

export async function AddUser(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/nftdrop/adduser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    console.log(e);
  }
  if (response.status == 200) {
    return await response.json();
  } else if (response.status === 401) {
    throw new Error("User Unauthorized. Please login again.");
  } else if (response.status === 400) {
    let errorResponse = await response.json();
    throw new Error(errorResponse.message);
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//------------Activate Email of a user in nft drop-----------

export async function ActivateEmail(body) {
  let response;
  try {
    response = await fetch(
      `/nftdrop/activate/email?id=${body.id}&code=${body.code}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {
    console.log(e);
  }
  if (response.status == 200) {
    return await response.json();
  } else if (response.status === 401) {
    throw new Error("User Unauthorized. Please login again.");
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//------------Activate Email of a user in nft drop-----------

export async function AddToNftDrop(body) {
  let response;
  try {
    response = await fetch(
      `/nftdrop/whitelist?id=${body.id}&address=${body.address}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {
    console.log(e);
  }
  if (response.status == 200) {
    return await response.json();
  } else if (response.status === 401) {
    throw new Error("User Unauthorized. Please login again.");
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function checkWhiteList(address) {
  // console.log("Request address", address)
  const response = await fetch(
    `https://0ke0x2pqqc.execute-api.us-east-1.amazonaws.com/whitelist/checkList/${address}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // console.log('Check whitelist Response', response)
  if (response.status == 200) {
    return await response.json();
  } else if (response.status === 401) {
    throw new Error("User Unauthorized. Please login again.");
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}
// -------------------- Proof --------------------
export async function GetProof(id, category) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL + "/v1/proof/" + id + "?category=" + category + "&tokenId=2",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//---------------BREEDING-----------------

export async function GetBreedProof(tokenID, category, token) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL + "/v1/proof/" + tokenID + "?category=" + category,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBreedingHistory(account) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/breeding/user/" + account, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetRoot() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/root?category=gold&tokenId=2", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------KIBBLEZ AND BONEZ -----------------------------------
export async function GetKibblezHistory(uid) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/kibblez/history/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBonezHistory(uid) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/bonez/history/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetWithdrawHistory(uid) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/bbi/withdraw/history/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ClaimKibblez(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/kibblez/claim", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Transferkibblez(body, token) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/kibblez/transfer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetClaimKibblez(id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/kibblez/claim/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetKibblezLeaderboard() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/kibblez/leaderboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetBonezLeaderboard() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/bonez/leaderboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetERC_20History(id) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/bbi/withdraw/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function WithrawERC_20(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/bonez/withdraw", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------GAME -----------------------------------
export async function GetGameInfo(id, type, tokenId) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL +
        "/v1/wallet/gameinfo/" +
        id +
        "?type=" +
        type +
        "&tokenId=" +
        tokenId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetTimeLapseGameInfo(tokenId, token) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL + "/v1/game/timelapse?tokenId=" + tokenId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetPlayableAssets(id) {
  let response;
  try {
    // response = await fetch("/postDetails/posts?postId=" + id, {
    response = await fetch(BACKEND_URL + "/v1/wallet/dogz/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------LEADERBOARD -----------------------------------
export async function GameLeaderBoard(time, mode, limit) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL +
        "/v1/game/cdmini/leaderboard?time=" +
        time +
        "&mode=" +
        mode +
        limit,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GameTimeLapseLeaderBoard(time, limit) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL + "/v1/game/timelapse/leaderboard?time=" + time + limit,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function SpaceCrateLeaderBoard(live) {
  let response;
  try {
    response = await fetch(
      "https://play.cyberdogz.io/battleship/v1/battleship/leaderboard?live=" +
        live,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------SPACESHIP CRATE -----------------------------------
export async function GetCratezProof(id, category) {
  let response;
  try {
    response = await fetch(
      BACKEND_URL + "/v1/sale/proof/" + id + "?category=" + category,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetCratezRoot(category) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/sale/root?category=" + category, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function NamePet(body, token) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/token/rename", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//----------------------------------- SPACEBATTLE -----------------------------------

export async function SignatureLogin(signature) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/wallet/logincode", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        signature: signature,
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//----------------------------------- SPACESHIPGAME -----------------------------------

export async function spaceshipGame(live) {
  let response;
  try {
    response = await fetch(
      "https://play.cyberdogz.io/spacebattle/v1/game/leaderboard?live=" + live,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//----------------------------------- Race -----------------------------------

export async function GetRaceEvents(page, active) {
  let response;
  try {
    response = await fetch(
      "https://play.cyberdogz.io/spacerace/v1/game/events?page=" +
        page +
        "&active=" +
        active,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetRaceLeaderbord(onlyWinners, page) {
  let response;
  try {
    response = await fetch(
      "https://play.cyberdogz.io/spacerace/v1/game/leaderboard?onlyWinners=" +
        onlyWinners +
        "&page=" +
        page,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function RegisterRace(signature, body) {
  let response;
  try {
    response = await fetch(
      "https://play.cyberdogz.io/spacerace/v1/game/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          signature: signature,
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//----------------------------------- Lottery -----------------------------------

export async function RegisterLottery(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/mail/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function LeaderBoardLottery() {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/lottery/winners?noOfDays=4", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyEmailAddress(body) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/mail/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function ResendMail(address) {
  let response;
  try {
    response = await fetch(BACKEND_URL + "/v1/mail/resend/" + address, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
