import { Col, Row, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { RiFileCopyLine } from "react-icons/ri";
import { minifyAddress } from "../../utils/helpers";
import styles from "./Referral.module.sass";
import { useLocation, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import {
  GetAllMyReferral,
  GetReferralBonez,
  GetReferralTransactions,
} from "../../services/ApiServices";
import moment from "moment";
import Pagenation from "../../components/Pagenation";
import cn from "classnames";
import ReferralBanner from "../../images/referralBanner.png";
import Refer from "../../images/Connect.png";
import BonezLogo from "../../images/bonezLogo.png";
import Dots from "../../images/raceDots.png";
import Dots1 from "../../images/raceDots1.png";
import Bonez from "../../images/bonez.png";
import TransactionLogo from "../../images/transactions.png";
import TableLoader from "../../components/TableLoader";
import { Bars } from "react-loader-spinner";
const Referral = (props) => {
  const navigate = useNavigate();
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [user, setUser] = useState();
  const state = useSelector((state) => state);
  const [referrals, setReferrals] = useState([{ docs: [] }]);
  const [transactions, setTransactions] = useState([{ docs: [] }]);
  const [bonez, setBonez] = useState(0);
  const [referralLoading, setReferralLoading] = useState(false);
  let location = useLocation();
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [origin, setOrigin] = useState("");
  useEffect(() => {
    if (state.User.user) setUser(state.User.user);
  }, [state.User.user]);
  useEffect(() => {
    if (account) {
      getAllReferral();
      getReferralTransactions();
      // getReferralBonez();
    } else navigate("/");
  }, [account]);
  const getAllReferral = async () => {
    setReferralLoading(true);
    let result;
    try {
      result = await GetAllMyReferral(account);
    } catch (e) {
      console.log(e);
      setReferralLoading(false);
    }
    try {
      if (result.success) {
        setReferralLoading(false);
        setReferrals(result.referral);
      }
    } catch (e) {
      setReferralLoading(false);
      console.log(e);
    }
  };
  const getReferralTransactions = async () => {
    setTransactionsLoading(true);
    let result;
    try {
      result = await GetReferralTransactions(account);
    } catch (e) {
      console.log(e);
      setTransactionsLoading(false);
    }
    try {
      if (result.success) {
        setTransactionsLoading(false);
        setTransactions(result.referral);
      }
    } catch (e) {
      setTransactionsLoading(false);
      console.log(e);
    }
  };
  const getReferralBonez = async () => {
    setBonez(0);
    let result;
    try {
      result = await GetReferralBonez(account);
    } catch (e) {
      console.log(e);
    }
    try {
      if (result.success) {
        setBonez(result.bonez[0].total ? result.bonez[0].total : 0);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setOrigin(window.location.origin);
  }, [window.location.origin]);
  return (
    <div className={styles.referral}>
      <div className={styles.heading}>Invite and earn</div>
      <div className={styles.description}>
        Get your friends to join with your unique URL and earn 15% of their
        winnings:
      </div>
      {user?.uid ? (
        <div className={styles.invite}>
          <div className={styles.referralCode}>
            <div className={styles.link}>
              {origin}/?referralId={user?.uid}
            </div>
          </div>
          <Tooltip title="Copied!" color="#f86c54" trigger="click">
            <div
              className={styles.copy}
              onClick={() => {
                navigator.clipboard.writeText(
                  origin + "/?referralId=" + user?.uid
                );
              }}
            >
              COPY LINK
            </div>
            {/* <RiFileCopyLine
                        className={styles.icon}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            origin + "/?referralId=" + user?.uid
                          );
                        }}
                      /> */}
          </Tooltip>
        </div>
      ) : (
        <Bars
          height="30"
          width="50"
          color="#FDC22E"
          ariaLabel="bars-loading"
          wrapperStyle={{
            // position: "absolute",
            // left: 0,
            // right: 0,
            // top: 60,
            justifyContent: "center",
          }}
          wrapperClass=""
          visible={true}
        />
      )}
    </div>
  );
};

export default Referral;
