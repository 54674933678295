import React, { useEffect, useState } from "react";
import styles from "./WidgetCode.module.sass";
import Highlight from "react-highlight";
import PrimaryButton from "../../../components/PrimaryButton";
import { FiClipboard } from "react-icons/fi";
import { Col, Row } from "antd";
const WidgetCode = ({
  user,
  data,
  fontFamily,
  fontSize,
  radiusLineWidth,
  radiusLineColor,
  backgroundLineWidth,
  backgroundColor,
}) => {
  let prefixCode =
    '<!DOCTYPE html>\n<html lang="en">\n  <head>\n    <title>widget</title>\n    <script src="https://spinwheel-belugaos.web.app/index.js"></script>\n  </head>\n  <body>\n    <noscript>You need to enable JavaScript to run this app.</noscript>\n    <div id="root"></div>\n    <div id="widget"></div>\n    <script>\n      let createWidget = new spinwheelWidget({\n       elementId: "widget",\n';
  let suffixCode =
    '       apiKey : "' +
    user.uid +
    '",\n       userAddress : "USER WALLET ADDRESS"\n       });\n       createWidget.init();\n    </script>\n  </body>\n</html>';
  const [code, setCode] = useState("");
  const [api, setApi] = useState("");
  const [pagenationApi, setPagenationApi] = useState("");
  const [userApi, setUserApi] = useState("");
  const [buttonText, setButtonText] = useState("Copy code");
  const [leaderboardJson, setLeaderboardJson] = useState(`{
    "message": "Spin wheel leaderboard",
    "spin": {
      "docs": [{
          "_id": "52668712ff23655ffs45f112",
          "userAddress": "0x0000000000000000000000000000000000000000",
          "date": "Wed Mar 29 2023 00:00:00 GMT+0000",
          "reward": "XYZ",
          "spinned": true,
          "timestamp": 1680094907142,
          "active": true
        }],
      "totalDocs": 1,
      "limit": 10,
      "totalPages": 1,
      "page": 2,
      "pagingCounter": 1,
      "hasPrevPage": false,
      "hasNextPage": false,
      "prevPage": null,
      "nextPage": null
    },
    "success": true
  }
  `);
  const [leaderboardUserJson, setLeaderboardUserJson] = useState(`{
    "message": "Spin wheel leaderboard",
    "spin": {
      "docs": [{
          "_id": "52668712ff23655ffs45f112",
          "userAddress": "0x0000000000000000000000000000000000000000",
          "date": "Wed Mar 29 2023 00:00:00 GMT+0000",
          "reward": "XYZ",
          "spinned": true,
          "timestamp": 1680094907142,
          "active": true
        }],
      "totalDocs": 1,
      "limit": 10,
      "totalPages": 1,
      "page": 2,
      "pagingCounter": 1,
      "hasPrevPage": false,
      "hasNextPage": false,
      "prevPage": null,
      "nextPage": null
    },
    "success": true
  }
  `);
  const [isLeaderboard, setISLeaderboard] = useState(true);
  const [isPaginationLeaderboard, setIsPaginationApi] = useState(true);
  const [isUserLeaderboard, setIsUserLeaderboard] = useState(true);
  const [active, setActive] = useState();
  const [generalApiResult, setGeneralApiResult] = useState(false);
  const [userApiResult, setUserApiResult] = useState(false);
  const [pagenationApiResult, setpagenationApiResult] = useState(false);
  useEffect(() => {
    let dataCode = "       data : [\n";
    let bgCode = "       backgroundColors : [\n";
    let styleCode = "";
    data.map((d, index) => {
      dataCode = dataCode + '\t "' + d.option + '",' + "\n";
      bgCode = bgCode + '\t "' + d.style.backgroundColor + '",' + "\n";
    });
    dataCode = dataCode + "       ],\n";
    bgCode = bgCode + "       ],\n";
    styleCode = '       fontFamily : "' + fontFamily + '",\n';
    styleCode = styleCode + "       fontSize : " + fontSize + ",\n";
    styleCode =
      styleCode + "       radiusLineWidth : " + radiusLineWidth + ",\n";
    styleCode =
      styleCode + '       radiusLineColor : "' + radiusLineColor + '",\n';
    styleCode =
      styleCode + "       backgroundLineWidth : " + backgroundLineWidth + ",\n";
    styleCode =
      styleCode + '       backgroundColor : "' + backgroundColor + '",\n';
    setCode(prefixCode + dataCode + bgCode + styleCode + suffixCode);
    setApi(`const getLeaderBoard = async () => {
    let response;
    try {
      response = await fetch("https://api.belugaos.com/v1/widget/leaderboard/${user.uid}",  // ${user.uid} is your Api Key
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      console.log(e.message)
    }
  }`);
    setPagenationApi(`const getLeaderBoard = async () => {
    let response;
    try {
      response = await fetch("https://api.belugaos.com/v1/widget/leaderboard/${user.uid}?page=2&limit=10",  // ${user.uid} is your Api Key
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      console.log(e.message)
    }
  }`);
    setUserApi(`const getUserLeaderBoard= async () => {
    let response;
    try {
      response = await fetch("https://api.belugaos.com/v1/widget/leaderboard/${user.uid}?userAddress=USER WALLET ADDRESS",  
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (e) {
      console.log(e.message)
    }
  }`);
  }, [
    user,
    data,
    fontFamily,
    fontSize,
    radiusLineWidth,
    radiusLineColor,
    backgroundLineWidth,
    backgroundColor,
  ]);

  const handleClick = (code) => {
    navigator.clipboard.writeText(code);
    setButtonText("Copied !");
  };
  useEffect(() => {
    let timer;
    if (buttonText === "Copied !") {
      timer = setTimeout(() => {
        setButtonText("Copy code");
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [buttonText]);

  return (
    <div className={styles.widgetCode}>
      {/* <PrimaryButton onClick={()=>{navigator.clipboard.writeText(code);}} label="Copy" /> */}
      <div className={styles.header}>
        <div className={styles.active}>HTML</div>
        <div className={styles.copy} onClick={() => handleClick(code)}>
          {buttonText != "Copied !" && <FiClipboard className={styles.icon} />}
          {buttonText}
        </div>
      </div>
      <Highlight language="HTML" className={styles.code}>
        {code}
      </Highlight>
      <div className={styles.heading}>Leaderboard Api's</div>
      <Row>
        <Col xs={24} sm={24} lg={12} md={12}>
          <div className={styles.top}>
            <div className={styles.subTitle}>Get leaderboard</div>
          </div>
          <div className={styles.definition}>
            <div className={styles.para}>
              This API is used to get all spin lists by passing your API key as
              a parameter. You can get pagination and send the limit also like
              using the query parameter{" "}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} md={12}>
          <div className={styles.header}>
            <div
              className={!generalApiResult ? styles.active : styles.title}
              onClick={() => {
                setISLeaderboard(true);
                setGeneralApiResult(false);
              }}
            >
              JavaScript
            </div>
            <div
              className={generalApiResult ? styles.active : styles.result}
              onClick={() => {
                setISLeaderboard(false);
                setGeneralApiResult(true);
              }}
            >
              Output
            </div>
            {isLeaderboard && (
              <div className={styles.copy} onClick={() => handleClick(api)}>
                {buttonText != "Copied !" && (
                  <FiClipboard className={styles.icon} />
                )}
                {buttonText}
              </div>
            )}
          </div>
          <Highlight language="JSX" className={styles.code}>
            {isLeaderboard ? api : leaderboardJson}
          </Highlight>
        </Col>
      </Row>
      <br />

      <Row>
        <Col xs={24} sm={24} lg={12} md={12}>
          <div className={styles.top}>
            <div className={styles.subTitle}>Get Pagenation leaderboard</div>
          </div>
          <div className={styles.definition}>
            <div className={styles.para}>
              This API is used to get all spin lists by passing your API key as
              a parameter. You can get pagination and send the limit also like
              using the query parameter{" "}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} md={12}>
          <div className={styles.header}>
            <div
              className={!pagenationApiResult ? styles.active : styles.title}
              onClick={() => {
                setIsPaginationApi(true);
                setpagenationApiResult(false);
              }}
            >
              JavaScript
            </div>
            <div
              className={pagenationApiResult ? styles.active : styles.result}
              onClick={() => {
                setIsPaginationApi(false);
                setpagenationApiResult(true);
              }}
            >
              Output
            </div>
            {isPaginationLeaderboard && (
              <div
                className={styles.copy}
                onClick={() => handleClick(pagenationApi)}
              >
                {buttonText != "Copied !" && (
                  <FiClipboard className={styles.icon} />
                )}
                {buttonText}
              </div>
            )}
          </div>
          <Highlight language="JSX" className={styles.code}>
            {isPaginationLeaderboard ? pagenationApi : leaderboardJson}
          </Highlight>
        </Col>
      </Row>
      <br />

      <Row>
        <Col xs={24} sm={24} lg={12} md={12}>
          <div className={styles.top}>
            <div className={styles.subTitle}>Get user leaderboard</div>
          </div>
          <div className={styles.definition}>
            <div className={styles.para}>
              This API is used to get all spin lists by passing your API key as
              a parameter along with user wallet address as query parameter. You
              can get pagination and send the limit also like using the query
              parameter{" "}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} md={12}>
          <div className={styles.header}>
            <div
              className={!userApiResult ? styles.active : styles.title}
              onClick={() => {
                setIsUserLeaderboard(true);
                setUserApiResult(false);
              }}
            >
              JavaScript
            </div>
            <div
              className={userApiResult ? styles.active : styles.result}
              onClick={() => {
                setUserApiResult(true);
                setIsUserLeaderboard(false);
              }}
            >
              Output
            </div>
            {isUserLeaderboard && (
              <div className={styles.copy} onClick={() => handleClick(userApi)}>
                {buttonText != "Copied !" && (
                  <FiClipboard className={styles.icon} />
                )}
                {buttonText}
              </div>
            )}
          </div>
          <Highlight language="JSX" className={styles.code}>
            {isUserLeaderboard ? userApi : leaderboardUserJson}
          </Highlight>
        </Col>
      </Row>
    </div>
  );
};
export default WidgetCode;
