import React, { useState, useEffect, useCallback } from "react";
import { Wheel } from "paramall-wheel-of-fortune";
import styles from "./Spin.module.sass";
import PrimaryButton from "../../components/PrimaryButton";
import BlackModal from "../../components/BlackModal";
import SpinModal from "./SpinModal";
import SpinLeaderBoard from "./SpinLeaderBoard";
import SpinArrow from "../../images/spinArrow.png";
import { useSnackbar } from "notistack";
import { useWeb3React } from "@web3-react/core";
import {
  CreateExtraSpin,
  CreateFreeSpin,
  GetSpinDetails,
  GetSpinLeaderboard,
  UpdateExtraSpin,
} from "../../services/ApiServices";
import ERC20 from "../../abi/ERC20.json";
import { ethers, providers, utils } from "ethers";
import {
  BBI_TOKEN_ADDRESS,
  ETHEREUM_NFT_ADDRESS,
  ETHEREUM_NFT_TRANSFER_ADDRESS,
  MUMBAI_USDT_TOKEN_ADDRESS,
} from "../../Config/config";
import TransparentButton from "../../components/TransparentButton";
import { Col, Divider, Row, Statistic, Tooltip } from "antd";
import CouponModal from "./CouponModal";
import LabelButton from "../../components/LabelButton";
import { toDisplay } from "../../utils/helpers";
import { useActiveWeb3React } from "../../hooks";
import moment from "moment";
import Referral from "../../components/Referral";
import ReferralLeaderboard from "../../components/Referral/LeaderBoard";
import { getUser } from "../../redux";
import { useDispatch } from "react-redux";
import TransactionsLeaderBord from "./TransactionsLeaderBoard";
import Customization from "../../components/Customize";

const Spin = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { Countdown } = Statistic;
  const [spin, setSpin] = useState(false);
  const [spinDetails, setSpinDetails] = useState({});
  const [spinLoading, setSpinLoading] = useState(false);
  const [spinModal, setSpinModal] = useState(false);
  const [win, setWin] = useState("");
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const data = [
    {
      option: "Free Spin",
      style: { backgroundColor: "#EB5757", textColor: "black" },
    },
    {
      option: "Loss",
      style: { backgroundColor: "#121212", textColor: "white" },
    },
    {
      option: "1 $BBI",
      style: { backgroundColor: "#00A2FD", textColor: "black" },
    },
    {
      option: "5 $BBI",
      style: { backgroundColor: "#BB6BD9", textColor: "black" },
    },
    {
      option: "Free Spin",
      style: { backgroundColor: "#FA26A0", textColor: "black", fontSize: 14 },
    },
    {
      option: "100 $BBI",
      style: { backgroundColor: "#007580", textColor: "black" },
    },
    {
      option: "10 $BBI",
      style: { backgroundColor: "#863A6F", textColor: "black" },
    },
    {
      option: "Loss",
      style: { backgroundColor: "#000", textColor: "white" },
    },
    {
      option: "1,000 $BBI",
      style: { backgroundColor: "#CDB4DB", textColor: "black" },
    },

    {
      option: "10,000 $BBI ",
      style: { backgroundColor: "#A9333A", textColor: "black" },
    },
    {
      option: "5 $BBI",
      style: { backgroundColor: "#D4F6CC", textColor: "black" },
    },
    {
      option: "100 $BBI",
      style: { backgroundColor: "#61876E", textColor: "black" },
    },
  ];
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const { account, active, activate, error, deactivate } = useWeb3React();
  const [leaderboard, setLeaderboard] = useState([]);
  const [totalDocs, setToataDocs] = useState(0);
  const [page, setPage] = useState(1);
  const [errorMsg, setErrormsg] = useState("");
  const [couponModal, setCouponModal] = useState(false);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [extraSpinLoading, setExtraSpinLoading] = useState(false);
  const [txHash, setTxHash] = useState("");
  const [premiumSpin, setPremiumSpin] = useState(0);
  const { library, chainId } = useActiveWeb3React();
  const [balance, setBalance] = useState(0);
  const deadline = moment().utc().startOf("day").valueOf() + 86400000;
  // const { library } = useWeb3React();
  const dispatch = useDispatch();

  const odds = [
    { reward: "Loss", odd: "1:10" },
    { reward: "1 $BBI", odd: "1:20" },
    { reward: "5 $BBI", odd: "1:30" },
    { reward: "Free spin", odd: "1:50" },
    { reward: "10 $BBI", odd: "1:100" },
    { reward: "100 $BBI", odd: "1:1000 " },
    { reward: "1,000 $BBI", odd: "1:5000" },
    { reward: "10,000 $BBI", odd: "1:100000" },
  ];
  const getBalance = useCallback(async () => {
    const balance = await library?.getBalance(account);
    if (typeof balance === "undefined") {
      return;
    }
    setBalance(toDisplay(balance));
  }, [account, library]);
  useEffect(() => {
    if (active) {
      getBalance();
    }
  }, [active, getBalance, library]);
  const balanceCheck = async () => {
    // spinFunction();
    // return;
    if (!active)
      return enqueueSnackbar("Connect your wallet", {
        variant: "error",
      });
    if (!spinDetails.freeSpin && !spinDetails.extraSpin) {
      return enqueueSnackbar("No Spin Available", {
        variant: "error",
      });
    }

    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    try {
      let Token = new ethers.Contract(BBI_TOKEN_ADDRESS, ERC20, signer);
      let bbiBalance, amount;
      try {
        bbiBalance = await Token.balanceOf(account);
        amount = utils.formatUnits(utils.parseUnits("0.01", "ether"), "wei");
        // amount = "100000000000000000";
        if (parseInt(bbiBalance) < parseInt(amount) && balance < 0.01) {
          setExtraSpinLoading(false);
          return enqueueSnackbar(
            "Minimum 0.01 $BBI or 0.01 $ETH Balance is required",
            {
              variant: "error",
            }
          );
        }
      } catch (e) {
        console.log(e);
        setExtraSpinLoading(false);
        return enqueueSnackbar(
          "Minimum 0.01 $BBI or 0.01 $ETH Balance is required",
          {
            variant: "error",
          }
        );
      }
    } catch (e) {
      console.log(e);
      setExtraSpinLoading(false);
      return setErrormsg("Metamask Error");
    }
    spinFunction();
  };

  const spinFunction = () => {
    setSpin(true);
    let random = Math.floor(Math.random() * 100000) + 1;
    let win = "";
    if (random >= 1 && random <= 25000) {
      setWin(data[1].option); // Loss
      setPrizeNumber(1);
      win = data[1].option;
    } else if (random >= 25001 && random <= 50000) {
      setWin(data[7].option); // Loss
      win = data[7];
      setPrizeNumber(7);
    } else if (random >= 50001 && random <= 70000) {
      setWin(data[2].option);
      setPrizeNumber(2); // 1 BBI
      win = data[2];
    } else if (random >= 70001 && random <= 75000) {
      setWin(data[3].option);
      setPrizeNumber(3); // 5 BBI
      win = data[3];
    } else if (random >= 75001 && random <= 80000) {
      setWin(data[10].option);
      setPrizeNumber(10); // 5 BBI
      win = data[10];
    } else if (random >= 80001 && random <= 87000) {
      setWin(data[0].option);
      setPrizeNumber(0); // Free Spin
      win = data[0];
    } else if (random >= 87001 && random <= 94000) {
      setWin(data[4].option);
      setPrizeNumber(4); // Free Spin
      win = data[4];
    } else if (random >= 94001 && random <= 96500) {
      setPrizeNumber(6); // 10 BBI
      setWin(data[6].option);
      win = data[6];
    } else if (random >= 96501 && random <= 98000) {
      setWin(data[5].option); //100 BBI
      setPrizeNumber(5);
      win = data[5];
    } else if (random >= 98001 && random <= 99500) {
      setWin(data[11].option); // 100 BBI
      setPrizeNumber(11);
      win = data[11];
    } else if (random >= 99501 && random <= 99990) {
      setWin(data[8].option); //1000 BBI
      setPrizeNumber(8);
      win = data[8];
    } else if (random >= 99991 && random <= 100000) {
      setWin(data[9].option); //10000 BBI
      setPrizeNumber(9);
      win = data[9];
    }
    // setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  useEffect(() => {
    if (account) {
      getSpinDetails();
      spinLeaderBoard(1);
    }
    setSpinDetails({});
    setLeaderboard([]);
    setTxHash("");
    setErrormsg("");
    setToataDocs(0);
    setPage(1);
  }, [account]);
  const getSpinDetails = async () => {
    setSpinLoading(true);
    let result;
    try {
      result = await GetSpinDetails(account);
    } catch (e) {
      console.log(e);
      setSpinLoading(false);
      // setSpin(true);
      // setMustSpin(true);
    }
    try {
      if (result.success) {
        setSpinDetails(result.spin);
        setSpin(false);
        if (result.spin?.freeSpin || result.spin?.extraSpin) {
          setMustSpin(false);
        }
      }
    } catch (e) {
      console.log(e);
      // setSpin(true);
      // setMustSpin(true);
    }
  };
  const createFreeSpin = async (reward) => {
    setSpinLoading(true);
    let body = { address: account, reward: reward };
    let result;
    try {
      result = await CreateFreeSpin(body);
    } catch (e) {
      console.log(e);
      setSpinLoading(false);
    }
    try {
      if (result.success) {
        setSpin(false);
        getSpinDetails();
        spinLeaderBoard(1);
        dispatch(getUser(account));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const leaderboardType = (page,type) => {
    spinLeaderBoard(page,type);
  };
  const spinLeaderBoard = async (page, type) => {
    let result;
    setLeaderboardLoading(true);
    let query = "?page=" + page;
    if (type && type == "My Rewards") {
      query = query + "&address=" + account;
    }
    try {
      result = await GetSpinLeaderboard(query);
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
    try {
      if (result.success) {
        setLeaderboard(result.spin.docs);
        setToataDocs(result.spin.totalDocs);
        setPage(page);
        setLeaderboardLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLeaderboardLoading(false);
    }
  };
  const updateExtraSpin = async (reward) => {
    setSpinLoading(true);
    let body = { address: account, reward: reward };
    let result;
    try {
      result = await UpdateExtraSpin(body);
    } catch (e) {
      console.log(e);
      setSpinLoading(false);
    }
    try {
      if (result.success) {
        getSpinDetails();
        spinLeaderBoard(1, "My Rewards");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onFinish = () => {
    getSpinDetails();
  };
  const buyExtraSpin = async () => {
    if (!active)
      return enqueueSnackbar("Connect your wallet", {
        variant: "error",
      });
    setExtraSpinLoading(true);
    setTxHash("");
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    try {
      let network = await provider.getNetwork();
      if (network.chainId !== 137) {
        setErrormsg("Please connect to Polygon Mainnet");
        return setExtraSpinLoading(false);
      }
      let Token = new ethers.Contract(BBI_TOKEN_ADDRESS, ERC20, signer);
      let tranfer, usdtBalance, amount, buyReceipt;
      try {
        usdtBalance = await Token.balanceOf(account);
        // amount = utils.formatUnits(utils.parseUnits("3", "ether"), "wei");
        amount = "3000000";
        // console.log(amount,usdtBalance.toString());
        if (parseInt(usdtBalance) < parseInt(amount)) {
          setExtraSpinLoading(false);
          return setErrormsg("Not enough USDT balance available");
        }
      } catch (e) {
        console.log(e);
        setExtraSpinLoading(false);
        return setErrormsg("Not enough USDT balance available");
      }
      try {
        tranfer = await Token.transfer(ETHEREUM_NFT_TRANSFER_ADDRESS, amount);
        setTxHash(tranfer.hash);
      } catch (e) {
        let message = e.message.split(":");
        console.log("Tranfer ", message, e);
        setExtraSpinLoading(false);
        return setErrormsg(message[1]);
      }
      try {
        buyReceipt = await tranfer.wait();
      } catch (e) {
        let message = e.message.split(":");
        console.log("Error on wait.. ", message);
        setExtraSpinLoading(false);
        return setErrormsg(message[1]);
      }
      // console.log(tranfer, buyReceipt.status);

      if (buyReceipt.status) {
        getSpinDetails();
        setExtraSpinLoading(false);
        setTxHash("");
        return;
      } else {
        getSpinDetails();
        setExtraSpinLoading(false);
        setTxHash("");
        return;
      }
    } catch (e) {
      let message = e.message.split(":");
      console.log("Tranfer Failed", message);
      setExtraSpinLoading(false);
      return setErrormsg("Tranfer Failed!");
    }
  };
  return (
    <div className={styles.spin}>
      <div className={styles.heading}>Your $BBI Spin Wheel</div>
      <div className={styles.subTitle}>
        No purchase necessary. Daily free spin for wallets with minimum .01 ETH
        or .01 BBI.
      </div>
      <div className={styles.spindiv}>
        <div className={styles.spinWheel}>
          <div className={styles.wheel}>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              backgroundColors={["white", "yellow"]}
              textColors={["#ffffff"]}
              fontFamily="Poppins"
              fontSize={16}
              radiusLineColor="#ffb602"
              radiusLineWidth={3}
              innerBorderColor="#ffb602"
              outerBorderWidth={0}
              // outerBorderColor="#ffb400"
              onStopSpinning={() => {
                setSpinModal(true);
                if (spinDetails.freeSpin) createFreeSpin(prizeNumber);
                else if (spinDetails.extraSpin) updateExtraSpin(prizeNumber);
                else
                  enqueueSnackbar("No spins available", {
                    variant: "error",
                  });
              }}
              pointerProps={{
                src: SpinArrow,
                style: {
                  transform: "rotate(0.75turn)",
                  width: deviceWidth > 600 ? "50px" : "35px",
                  right: 0,
                  left: 0,
                  margin: "0 auto",
                  top: "-15px",
                },
              }}
            />
          </div>

          {/* <div className={styles.spinButton}>
            <TransparentButton
              onClick={() => {
                balanceCheck();
              }}
              disabled={spin}
              label="Spin"
            />
          </div> */}
          <div className={styles.spinButton}>
            <LabelButton
              onClick={() => {
                balanceCheck();
              }}
              disabled={spin}
              label="Spin"
            />
          </div>
          <div className={styles.disclaimer}>
            This is in beta and not a financial product. $BBI are game tokens
            and we make no guarantee/warranty express or implied of any value,
            liquidity or price. All products and services are "As Is". Stay safe
            fam.
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.spinCount}>
            <div>
              <div className={styles.free}>
                Free Spin :{" "}
                <span className={styles.value}>
                  {spinDetails?.freeSpin ? spinDetails?.freeSpin : 0}
                </span>
              </div>
              <div className={styles.premium}>
                Premium Spin :{" "}
                <span className={styles.value}>
                  {spinDetails?.extraSpin ? spinDetails?.extraSpin : 0}
                </span>
              </div>

              {active && !spinDetails.freeSpin && (
                <div className={styles.countdown}>
                  Your next free spin in
                  <Countdown
                    value={deadline}
                    onFinish={onFinish}
                    format="HH:mm:ss"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.odds}>
            <div className={styles.head}>
              <Row>
                <Col className={styles.field} span={12}>
                  Prize
                </Col>
                <Col className={styles.field} span={12}>
                  Odds
                </Col>
              </Row>
            </div>
            <Divider
              style={{
                minWidth: "80%",
                width: "80%",
                borderTop: "1px solid white",
                margin: "5px auto",
              }}
              orientation="center"
            />
            {odds.map((odd, index) => (
              <Row key={index}>
                <Col className={styles.oddsValue} span={12}>
                  {odd.reward}
                </Col>
                <Col className={styles.oddsValue} span={12}>
                  {odd.odd}
                </Col>
              </Row>
            ))}
          </div>
        </div>
        <div className={styles.left}>
          {active && (
            <div className={styles.referral}>
              <Referral />
            </div>
          )}
          <div className={styles.coupon}>
            Use the Coupon code here <br /> to get Premium Spin.
            {/* <Tooltip title="Refer reward page in my profile" color={"gold"}>
            <HiOutlineInformationCircle className={styles.icon} />
          </Tooltip> */}
            <div>
              <PrimaryButton
                className={styles.codeButton}
                onClick={() => {
                  if (!active) {
                    return enqueueSnackbar("Connect your wallet", {
                      variant: "error",
                    });
                  } else setCouponModal(true);
                }}
                label="Enter Code"
              />
            </div>
          </div>
          {active && (
            <div className={styles.referral}>
              <Customization />
            </div>
          )}
        </div>
        {false && (
          // {!spinDetails?.extraSpin && !spinDetails?.freeSpin && (
          <div className={styles.spinned}>
            <div>
              <div className={styles.spinText}>No free spin available</div>
              <div className={styles.extra}>
                $3 USDT (MATIC) per premium spin
              </div>
              {errorMsg && <div className={styles.error}>{errorMsg}</div>}
              {txHash && (
                <div className={styles.trasaction}>
                  Transaction submitted.{" "}
                  <a
                    href={"https://polygonscan.com/tx/" + txHash}
                    target="_blank"
                  >
                    View in explorer
                  </a>
                </div>
              )}
              <div className={styles.spinButton}>
                <PrimaryButton
                  onClick={() => {
                    buyExtraSpin();
                  }}
                  loading={extraSpinLoading}
                  label="Buy Extra Spin"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <SpinLeaderBoard
        leaderboard={leaderboard}
        loading={leaderboardLoading}
        totalDocs={totalDocs}
        setPage={(page) => {
          setPage(page);
          spinLeaderBoard(page);
        }}
        leaderboardType={leaderboardType}
        page={page}
      />

      <ReferralLeaderboard />
      <TransactionsLeaderBord />

      <BlackModal
        visible={spinModal}
        onClose={() => {
          setSpinModal(false);
        }}
      >
        <SpinModal reward={win} />
      </BlackModal>
      <BlackModal
        visible={couponModal}
        onClose={() => {
          setCouponModal(false);
        }}
        outerClassName={styles.couponModal}
      >
        <CouponModal
          increaseSpin={getSpinDetails}
          onClose={() => {
            setCouponModal(false);
          }}
        />
      </BlackModal>
    </div>
  );
};
export default Spin;
